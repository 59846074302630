<template>
  <div></div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Autocertificados',
  computed: {
    ...mapState('auth', ['uid']),
  },
  created() {
    const urlParams = '?isPortalSenior=true&employeeId=' + this.uid
    window.open(process.env.VUE_APP_CERTS + urlParams, '_blank')
  },
}
</script>
